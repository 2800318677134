<template>
	<div class="audit">
		<frame :top1="top1" :top2="top2">
			<!-- <search @search='search' :flag='flag'></search> -->
			<lists :titles='titles' :contentC="datas" @refresh='refresh'></lists>
			<!-- <page :num='num'></page> -->
			<el-pagination
			  @size-change="handleSizeChange"
			  @current-change="handleCurrentChange"
			  :current-page="currentPage4"
			  :page-sizes="[10,15]"
			  :page-size="pageSize"
			  layout="total, sizes, prev, pager, next, jumper"
			  :total="titlePage"
			>
			</el-pagination>
		</frame>
	</div>
</template>

<script>
	import frame from '../public/Frame.vue';
	import search from './componAudit/SearchAudit.vue';
	import lists from './componAudit/AuditList.vue';
	import page from './componList/Page.vue';
	export default {
		name: 'audit',
		data() {
			return {
				flag: 1,
				top1: '4-1',
				top2: ['4'],
				titles: ['编号','商品图片','商品名称','店铺名称','价格/货号','标签','销量','审核状态','操作'],
				num: 10,
				datas: null,
				currentPage4: 1,
				titlePage:1,
				currentpage: 1,
				pageSize:15,
			}
		},
		components: {
			frame,
			search,
			lists,
			page
		},
		created() {
			let token = this.$storage.getLocal('token');
			let type = this.$storage.getLocal('type');
			this.$request.selGoodsReviewData({token,type,page:1,limit:15}).then(res=>{
				if(res.code == 0){
					this.datas = res.data
					this.titlePage=res.count
				}
			})
		},
		methods:{
			handleSizeChange(val) {
			  console.log(`每页 ${val} 条`);
			  
			   this.pageSize = val;
			let token = this.$storage.getLocal('token');
			let type = this.$storage.getLocal('type');
			this.$request.selGoodsReviewData({token,type,page:1,limit:this.pageSize}).then(res=>{
				if(res.code == 0){
					this.datas = res.data
					// this.titlePage=res.data.count
				}
			})
			},
			
			handleCurrentChange(val) {
			 let token = this.$storage.getLocal('token');
			 let type = this.$storage.getLocal('type');
			 this.$request.selGoodsReviewData({token,type,page:val,limit:this.pageSize}).then(res=>{
			 	if(res.code == 0){
			 		this.datas = res.data
			 		// this.titlePage=res.data.count
			 	}
			 })
			    },
			refresh(){
				let token = this.$storage.getLocal('token');
				let type = this.$storage.getLocal('type');
				this.$request.selGoodsReviewData({token,type}).then(res=>{
					if(res.code == 0){
						this.datas = res.data
					}
				})
			},
			search(data){
				console.log(data)
			}
		}
	}
</script>

<style scoped="scoped">
</style>
