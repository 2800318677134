<template>
	<div class="content">
		<div class="liubai">
			<Icon type="ios-search" />筛选查询
		</div>
		<div class="search">
			<div class="input">
				<div class='text'>请输入：</div>
				<Input v-model="value14" placeholder="请输入商品名称" clearable style="width: 200px" />
			</div>
			<div class="input">
				<div class='text'></div>
				<Form :model="formItem" :label-width="80">
					<FormItem label="商品分类:">
						<Select v-model="formItem.select1">
							<Option :value="item1" v-for='item1,index1 in selects1' :key='index1'>{{item1}}</Option>
							<!-- <Option value="shanghai">London</Option> -->
							<!-- <Option value="shenzhen">Sydney</Option> -->
						</Select>
					</FormItem>
				</Form>
			</div>
			<div class="input">
				<div class='text'></div>
				<Form :model="formItem" :label-width="80">
					<FormItem label="店铺品牌:">
						<Select v-model="formItem.select2">
							<Option :value="item2" v-for='item2,index2 in selects2' :key='index2'>{{item2}}</Option>
							<!-- <Option value="shanghai">London</Option> -->
							<!-- <Option value="shenzhen">Sydney</Option> -->
						</Select>
					</FormItem>
				</Form>
			</div>
			<div class="input">
				<div class='text'></div>
				<Form :model="formItem" :label-width="80">
					<FormItem label="店铺名称:">
						<Select v-model="formItem.select3">
							<Option :value="item3" v-for='item3,index3 in selects3' :key='index3'>{{item3}}</Option>
							<!-- <Option value="shanghai">London</Option> -->
							<!-- <Option value="shenzhen">Sydney</Option> -->
						</Select>
					</FormItem>
				</Form>
			</div>
			<div class="input">
				<Button type="success" @click='search()'>搜索</Button>
			</div>
		</div>
	</div>

</template>

<script>
	export default {
		name: 'search',
		data() {
			return {
				value14: '',
				formItem: {
					select1: '',
					select2: '',
					select3: ''
				},
				selects1: [1,1],
				selects2: [2,2],
				selects3: [3,3]
			}
		},
		created() {
			// let token = this.$storage.getLocal('token');
			// this.$request.selGoodsStoreName({token}).then(res=>{
			// 	if(res.code == 0){ 
			// 		this.selects = res.data;
			// 	}
			// })
		},
		methods: {
			search(){
				this.$emit('search',{text:this.value14,select:this.formItem})
			}
		}
	}
</script>

<style scoped="scoped">
	.search {
		padding-left: 200px;
		display: flex;
		/* justify-content: center; */
		text-align: left;
	}

	.input {
		/* width: 80%; */
		/* margin: 0 auto; */
		display: flex;
		justify-content: space-between;
		line-height: 30px;
		margin-left: 50px;
		padding: 10px 0 10px 0;
	}

	.liubai {
		width: 100%;
		height: 40px;
		background-color: #F3F3F3;
		font-size: 15px;
		text-align: left;
		padding-left: 220px;
		line-height: 40px;
	}
</style>
