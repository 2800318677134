<template>
	<div class="list">
		<el-dialog title="详情" :visible.sync="dialogVisible" width="30%">
			<span>这是一段信息</span>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="dialogVisible = false">确 定</el-button>
			</span>
		</el-dialog>
		<Modal v-model="modal1" title="商品审核" @on-ok="ok" @on-cancel="cancel">
			<p>商品名称：{{gname}}</p>
			<p>店铺名称：{{sname}}</p>
			<p>商品审核：
				<RadioGroup v-model="select">
					<Radio label="1">
						<span>审核通过</span>
					</Radio>
					<Radio label="2">
						<span>审核不通过</span>
					</Radio>
				</RadioGroup>
			</p>
			<p>反馈详情：
				<Input v-model="value1" maxlength="100" show-word-limit type="textarea" placeholder="请输入反馈" style="width: 200px" />
			</p>
		</Modal>
		<Card style='margin-left: 200px'>
			<div style="height: 600px;">
				<div class="titles">审核列表</div>
				<div class="title">
					<slot></slot>
				</div>
				<div class="content">
					<div id="contents">
						<div class="cont">
							<table>
								<tr>
									<td class='titNa' v-for="item,index in titles" :key='index'>{{item}}</td>
								</tr>
								<tr v-for="item2,index2 in contentC" :key='index2'>
									<td>{{item2.gcode}}</td>
									<td>
										<img :src="imgUrl + item2.gimg" alt="">
									</td>
									<td>{{item2.gname}}</td>
									<td>{{item2.s_name}}</td>
									<td>{{item2.gcode}} / ￥{{item2.selling_price}}</td>
									<td>{{item2.labe_name}}</td>
									<td>{{item2.sale}}</td>
									<td>{{item2.is_gtype==1?'待审核':(item2.is_gtype==2?'审核通过':'未通过')}}</td>
									<td class="oper">
										<!-- <div class="stte" @click="examine(item2.gcid)">查看</div> -->
										<div class="del" @click="audit(item2.gid,item2.gname,item2.s_name)">审核</div>
									</td>
								</tr>
							</table>
						</div>
					</div>
				</div>
			</div>
		</Card>
	</div>
</template>

<script>
	export default {
		name: 'contents',
		props: {
			contentC: {
				type: Array,
			},
			titles: {
				type: Array,
			},
			flag: {
				type: Number,
			}
		},
		data() {
			return {
				social: [],
				dialogVisible: false,
				imgUrl: null,
				modal1: false,
				gname: null,
				sname: null,
				select: '2',
				value1: '',
				gid: null
			}
		},
		created() {
			this.imgUrl = this.$request.imgUrl2() + 'uploads/';
		},
		components: {

		},
		methods: {
			examine(id) { //查看
				this.dialogVisible = true
			},
			audit(id, gName, sName) { //审核
				this.modal1 = true;
				this.gname = gName;
				this.sname = sName;
				this.gid = id
			},
			ok() {
				let token = this.$storage.getLocal('token');
				this.$request.upGoodsReviewData({token,gid:this.gid,content:this.value1,status:this.select}).then(res=>{
					if(res.code == 0){
						this.$Message.info('成功！');
						this.$emit('refresh','ok');
					}
				})
			},
			cancel() {
				this.$Message.info('取消了审核');
			}
		}
	}
</script>

<style scoped="scoped">
	img {
		width: 20px;
	}

	table {
		width: 100%;
		background: #eeeeee;
	}

	td {
		background-color: #FFFFFF;
		font-size: 15px;
		height: 30px;
		/* width: 15%; */
	}

	tr .titNa {
		background-color: #F9FAFC;
		font-size: 15px;
		font-weight: 800;
	}

	.oper {
		display: flex;
		justify-content: space-around;
		color: #42B983;
	}
	.titles{
		margin-left: -95%;
	}
</style>
